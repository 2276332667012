<template>
  <div class="site-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>站点设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="setupForm" ref="setupRef" label-width="140px" class="setup-form">
        <el-form-item label="站点名称" prop="sitename">
          <el-input v-model="setupForm.sitename"></el-input>
        </el-form-item>
        <el-form-item label="备案号设置" prop="copyright">
          <el-input v-model="setupForm.copyright"></el-input>
        </el-form-item>
        <el-form-item label="技术支持" prop="support">
          <el-input v-model="setupForm.support"></el-input>
        </el-form-item>
        <el-form-item label="全局水印图片" prop="watermark">
          <upload :url="setupForm.watermark" :filed="'watermark'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="setupForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="联系二维码" prop="ewm">
          <upload :url="setupForm.ewm" :filed="'ewm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="微信充值二维码" prop="wechatEwm">
          <upload :url="setupForm.wechatEwm" :filed="'wechatEwm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="支付宝充值二维码" prop="aliEwm">
          <upload :url="setupForm.aliEwm" :filed="'aliEwm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="充值客服二维码" prop="paykf">
          <upload :url="setupForm.paykf" :filed="'paykf'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="腾讯云appid" prop="appid">
          <el-input v-model="setupForm.appid"></el-input>
          <div class="tips">
            <a href="https://console.cloud.tencent.com/cam/capi" target="_blank">点击查看</a>
          </div>
        </el-form-item>
        <el-form-item label="腾讯云secretId" prop="secretId">
          <el-input v-model="setupForm.secretId"></el-input>
          <div class="tips">
            <a href="https://console.cloud.tencent.com/cam/capi" target="_blank">点击查看</a>
          </div>
        </el-form-item>
        <el-form-item label="腾讯云secretKey" prop="secretKey">
          <el-input v-model="setupForm.secretKey"></el-input>
          <div class="tips">
            <a href="https://console.cloud.tencent.com/cam/capi" target="_blank">点击查看</a>
          </div>
        </el-form-item>
        <el-form-item label="存储方式" prop="upload_type">
          <el-radio-group v-model="setupForm.upload_type">
            <el-radio :label="1">服务器存储</el-radio>
            <el-radio :label="2">腾讯云存储</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认存储桶名称" prop="bucketname" v-if="setupForm.upload_type === 2">
          <el-input v-model="setupForm.bucketname"></el-input>
          <div class="tips">
            <a href="https://console.cloud.tencent.com/cos5/bucket" target="_blank">点击查看</a>
          </div>
        </el-form-item>
        <el-form-item label="COS源站域名" prop="domain" v-if="setupForm.upload_type === 2">
          <el-input v-model="setupForm.domain"></el-input>
          <div class="tips">
            <a href="https://console.cloud.tencent.com/cos5/bucket" target="_blank">点击查看</a>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'Site',
  components: {
    Upload
  },
  data () {
    return {
      setupForm: {
        sitename: '',
        copyright: '',
        support: '',
        watermark: '',
        phone: '',
        ewm: '',
        wechatEwm: '',
        aliEwm: '',
        paykf: '',
        appid: '',
        secretId: '',
        secretKey: '',
        upload_type: 1,
        bucketname: '',
        domain: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    // 获取表单信息
    async getData () {
      const { data: res } = await this.$http.get('/setup')
      if (res.code === 200) {
        this.setupForm = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 提交表单
    async submitForm () {
      const { data: res } = await this.$http.post('/setup', this.setupForm)
      if (res.code === 200) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.setupForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.setupForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.setupForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.setupForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.setup-form{
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}
</style>
